import { AgmMap, MapsAPILoader, MapTypeStyle } from '@agm/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { element } from 'protractor';
import { FirebaseService } from 'src/app/services/firebase.service';
import { RemoteService } from 'src/app/services/remote.service';

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.css'],
})
export class VendorComponent implements OnInit {
  firebaseBookingData: any = '';
  vendorVehicle: any[] = [];
  vehicleId: any[] = [];
  originLat = 0.0;
  originLng = 0.0;
  markers: any[] = [];
  allData: any[] = [];
  zoom = 12;
  lat: number = 13.067439;
  lng: number = 80.237617;
  vendorId: any = '';
  available: any = true;

  @ViewChild('AgmMap') agmMap: AgmMap;
  Styles: MapTypeStyle[] = [];

  constructor(
    private remoteService: RemoteService,
    private firebaseService: FirebaseService,
    private route: ActivatedRoute,
    public mapsAPILoader: MapsAPILoader
  ) {
    this.JSON_MAP_STYLES.forEach((style: any) => {
      this.Styles.push(style);
    });
  }

  ngOnInit(): void {
    console.log('vendor id calling ------> ', this.vendorId);
    if (this.route.snapshot.params.id != null) {
      this.vendorId = this.route.snapshot.params.id;
      this.getFirebaseBooking();
    } else {
      console.log('No Data Found');
    }
  }

  getFirebaseBooking() {
    try {
      this.firebaseService
        .bookingAllDet()
        .valueChanges()
        .subscribe((data) => {
          if (data != null) {
            this.firebaseBookingData = data;
            if (this.firebaseBookingData != '') {
              this.getVendorVehicle();
              this.available = true;
            }
          }else{
            this.available = false;
          }
        });
    } catch (error) {
      console.log('catch calling -----> ', error);
    }
  }

  getVendorVehicle() {
    try {
      if (this.vendorId != '') {
        var obj = { vendor_id: this.vendorId };
        this.remoteService.getVendorVehicle(obj).subscribe((data: any) => {
          console.log("remote service response ------> ", data);
          if (data.success == true) {
            data.data.forEach((element) => {
              this.vehicleId.push(element['vechicle_id']);
            });
            this.trackVendorVehicle();
          } else {
            console.log('error -----> ', data['message']);
          }
        });
      }
    } catch (error) {}
  }

  /*
  Track all vehicle based on vendor
  */
  trackVendorVehicle(): void {
    try {
      if (this.vehicleId.length > 0) {
        for (var i = 0; i < this.vehicleId.length; i++) {
          let tempVehicleId = this.vehicleId[i];
          this.firebaseService
            .vechileDet(this.vehicleId[i])
            .valueChanges()
            .subscribe((data) => {
              if (data != null) {
                this.vendorVehicle = data;
                for (let k in this.firebaseBookingData) {
                  if (
                    this.firebaseBookingData[k]['vechicle_id'] != undefined &&
                    this.firebaseBookingData[k]['vechicle_id'] == tempVehicleId
                  ) {
                    this.originLat = data.latitude;
                    this.originLng = data.longitude;
                    let ori = {
                      lat: this.originLat,
                      lng: this.originLng,
                      driver_no : this.firebaseBookingData[k]["driver_mobile_no"],
                      vehicle_no: this.firebaseBookingData[k]["vechicle_number"],
                      drop_address: this.firebaseBookingData[k]["drop_location_address"],
                      label: 'Driver',
                      icon: this.firebaseBookingData[k]["vechicle_cat_id"] == 1 ?  '../../../../assets/orig.png' : '../../../../assets/BP.png',
                    };

                    this.markers.push(ori);
                  }
                }
              }
            });
        }
      }
    } catch (error) {
      console.log('track all vehicle catch ------> call ', error);
    }
  }

  JSON_MAP_STYLES = [
    { 
      featureType: 'administrative',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#444444',
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'all',
      stylers: [
        {
          color: '#f2f2f2',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 45,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          color: '#bae3f7',
        },
        {
          visibility: 'on',
        },
      ],
    },
  ];
}
