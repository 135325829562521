import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MapComponent } from './modules/map/map.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AgmCoreModule } from '@agm/core';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MessageComponent } from './modules/message/message.component';
import { AgmDirectionModule } from 'agm-direction'; 
import { LottieAnimationViewModule } from 'ng-lottie';
import { VehicleComponent } from './modules/vehicle/vehicle.component';
import { AdminComponent } from './modules/admin/admin.component';
import { VendorComponent } from './modules/vendor/vendor.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    MessageComponent,
    VehicleComponent,
    AdminComponent,
    VendorComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    LottieAnimationViewModule.forRoot(),
    AgmCoreModule.forRoot({
      //Live Key
      //apiKey:"AIzaSyAiwc_bDcQR3yFM5RhtnD-A6TfjUg_DwC8",
      //Local Key
      apiKey:"AIzaSyB05pReW8IsUyyv2RpbTc7bWFKw7ptfrrQ",
    }),
    AgmDirectionModule,
  ],
  providers: [
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
