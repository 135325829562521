<mat-card  *ngIf="available == true; else thenBlock">
  <agm-map [styles]="Styles" [zoomControl]="true" [maxZoom]="25" [zoom]="3" [latitude]="lat" [longitude]="lng"
    [streetViewControl]="false" ter [keyboardShortcuts]="false" class="mapSize" [zoom]="zoom">
    <agm-marker *ngFor="let data of markers; let i = index" [iconUrl]="data.icon" [latitude]="data.lat"
      [longitude]="data.lng" [markerClickable]="true">
      <agm-info-window>
        <table>
          <tr>
            <th>Driver No</th>
            <th>vehicle No</th>
            <th>Drop Address</th>
          </tr>
          <tr>
            <td>{{ data.driver_no }}</td>
            <td>{{ data.vehicle_no }}</td>
            <td>{{ data.drop_address }}</td>
          </tr>
        </table>
      </agm-info-window>
    </agm-marker>
  </agm-map>
</mat-card>

<ng-template #thenBlock>
  <div class="d-flex justify-content-center">
    <div class="col-md-4">
      <lottie-player src="../../../../assets/close.json" background="transparent" speed="1" autoplay>
      </lottie-player>
      <div class="d-flex justify-content-center">
        <h2>No Data Found...!</h2>
      </div>
    </div>
  </div>
</ng-template>