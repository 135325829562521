import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RemoteService {

  baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl;
  }

  getVendorVehicle(params:any): Observable<any> {
    console.log(this.baseUrl+"api/track_vendor_all_vehicle")
    try {
      return this.http.post(
        `${this.baseUrl + "api/track_vendor_all_vehicle"}`,
        params
      );
    } catch (error) {
      console.log("remote service ------> ", error);
    }
  }
}
