import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MapComponent } from './modules/map/map.component';
import { MessageComponent } from './modules/message/message.component';
import { VehicleComponent } from './modules/vehicle/vehicle.component';
import { AdminComponent } from './modules/admin/admin.component';
import { VendorComponent } from './modules/vendor/vendor.component';

const routes: Routes = [
  {
    path: '',
    component: MessageComponent
  },
  {
    path: 'map/:id',
    component: MapComponent
  },
  {
    path: 'vehicle',
    component: VehicleComponent
  },
  {
    path: 'admin',
    component: AdminComponent
  },
  {
    path: 'vendor/:id',
    component: VendorComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
