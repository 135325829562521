import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';
import {
  MapsAPILoader,
  AgmMap,
  MapTypeStyle,
} from '@agm/core';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements OnInit {
  firebaseBookingData: any = '';
  firebaseVehicleData: any = '';
  adminVehicle: any[] = [];

  public travelMode: string = 'DRIVING';

  options = {
    suppressMarkers: true,
    polylineOptions: {
      strokeColor: '#000080',
      strokeWeight: 6,
    },
  };

  /* Driver Detail variables */
  driverName: any;
  vehicleNum: any;
  driverNum: any;

  markers: any[] = [];
  polyline: any[] = [];
  position = {};
  start_end_mark: any[] = [];
  VechileData: any;
  bookingId: any;
  vechileId: any;
  available: any = true;
  dataStatus: any = false;
  // initial center position for the map
  zoom = 12;
  lat: number = 13.067439;
  lng: number = 80.237617;

  originLat = 0.0;
  originLng = 0.0;
  destLat = 0.0;
  destLng = 0.0;

  origin = {};
  destination = {};

  obj = {};

  @ViewChild('AgmMap') agmMap: AgmMap;
  Styles: MapTypeStyle[] = [];
  Length: any;
  VechileDataID: any[] = [];
  icon: any;
  bookingData: any;
  bookingDataLength: any;
  vechileLng: any;
  vechileLat: any;
  VechileDetails: any[] = [];
  constructor(
    private firebaseService: FirebaseService,
    private route: ActivatedRoute,
    public mapsAPILoader: MapsAPILoader,
    private db: AngularFireDatabase
  ) {
    this.JSON_MAP_STYLES.forEach((style: any) => {
      this.Styles.push(style);
    });
  }

  ngOnInit(): void {
    this.getFirebaseBooking();
    //this.vechileDetailsAll();
    //this.bookingDetails();
  }

  getFirebaseBooking() {
    try {
      this.firebaseService
        .bookingAllDet()
        .valueChanges()
        .subscribe((data) => {
          if (data != null) {
            this.firebaseBookingData = data;
            if (this.firebaseBookingData != '') {
              this.getFirebaseVehicle();
            }
          }
        });
    } catch (error) {
      console.log('catch calling -----> ', error);
    }
  }

  getFirebaseVehicle() {
    try {
      this.firebaseService
        .vechileDetAll()
        .valueChanges()
        .subscribe((data) => {
          if (data != null) {
            // for(var i in data){
            //   console.log("firebase vehicle data ------> ", i)
              
            // }
            this.firebaseVehicleData = data;
            this.trackVendorVehicle();
            this.available = true;
            
          }else{
            this.available = false;
          }
        });
    } catch (error) {
      console.log('catch calling -----> ', error);
    }
  }

   /*
  Track all vehicle based on vendor
  */
  trackVendorVehicle(): void {
    try {
      if (this.firebaseVehicleData != '') {
        for(var i in this.firebaseVehicleData){
          let tempVehicleId = i;
          let tempLat = this.firebaseVehicleData[i]["latitude"];
          let tempLng = this.firebaseVehicleData[i]["longitude"];
          for (let k in this.firebaseBookingData) {
            if (
              this.firebaseBookingData[k]['vechicle_id'] != undefined &&  
              this.firebaseBookingData[k]['vechicle_id'] == tempVehicleId
            ) {
              this.originLat = tempLat;
              this.originLng = tempLng;
              let ori = {
                lat: this.originLat,
                lng: this.originLng,
                driver_no : this.firebaseBookingData[k]["driver_mobile_no"],
                vehicle_no: this.firebaseBookingData[k]["vechicle_number"],
                drop_address: this.firebaseBookingData[k]["drop_location_address"],
                label: 'Driver',
                icon: this.firebaseBookingData[k]["vechicle_cat_id"] == 1 ?  '../../../../assets/orig.png' : '../../../../assets/BP.png',
              };
              this.markers.push(ori);
            }
          }
        }
      }
      console.log("marker lenth -----> ", this.markers.length);
      // if(this.markers.length){
      //   this.playsound();
      // }
    } catch (error) {
      console.log('track all vehicle catch ------> call ', error);
    }
  }

  // vechileDetailsAll(): void {
  //   this.firebaseService
  //     .vechileDetAll()
  //     .valueChanges()
  //     .subscribe((data) => {
  //       //this.zoom =14;
  //       console.log("firebase data1 ==========> ", data);

  //       console.log("this length ---------> ", Object.keys(data));
  //       let lastvalue = this.Length;
  //       this.Length = Object.keys(data).length;
  //       if (lastvalue < this.Length) {
  //         console.log('---------->Sound');
  //         this.playsound();
  //       }
  //       this.VechileDataID = Object.values(data);
  //       this.VechileData = Object.keys(data);
  //       this.icon =
  //         'https://myrmc-stores.s3.ap-south-1.amazonaws.com/mapicon/maptm.png';

  //       console.log("-------> ", this.Length);

  //       for (let i = 0; i < this.Length; i++) {
  //         console.log("-------> ", this.VechileDataID[i])
  //         this.originLat = this.VechileDataID[i].latitude;
  //         this.originLng = this.VechileDataID[i].longitude;

  //         let ori = {
  //           lat: this.originLat,
  //           lng: this.originLng,
  //           label: 'Driver',
  //           icon: '../../../../assets/orig.png',
  //         };

  //         this.markers.push(ori);
  //       }

  //       // let ori = {
  //       //   lat: this.originLat,
  //       //   lng: this.originLng,
  //       //   label: 'Driver',
  //       //   icon: '../../../../assets/orig.png',
  //       // };
  //       let des = {
  //         lat: this.destLat,
  //         lng: this.destLng,
  //         label: 'Destination',
  //         icon: `<lottie-player src="../../../../assets/close.json" background="transparent" speed="1" autoplay>`,
  //       };
  //       let ori1 = [this.originLat, this.originLng];
  //       let des1 = [Number(this.destLat), Number(this.destLng)];
  //       this.lat = this.originLat;
  //       //this.lng = this.VechileDataID.longitude;
  //       this.origin = {
  //         lat: Number(this.originLat),
  //         lng: Number(this.originLng),
  //       };
  //       this.destination = {
  //         lat: Number(this.destLat),
  //         lng: Number(this.destLng),
  //       };
  //       this.polyline = [];
  //       // this.markers.push(ori);
  //       console.log("markers --------> ", this.markers);
  //       this.polyline.push(ori1);
  //       this.start_end_mark.push(this.polyline[0]);
  //       this.start_end_mark.push(this.polyline[this.polyline.length - 1]);

  //       for(let j = 0; j < this.markers.length; j++){
  //         console.log("for loop check -----> ", this.markers[j])
  //       }
  //     });
  // }

  playsound() {
    let audio = new Audio();
    audio.src = '../../../assets/Pikachu.mp3';
    audio.load();
    audio.play();
  }

  bookingDetails(): void {
    this.firebaseService
      .bookingAllDet()
      .valueChanges()
      .subscribe((data) => {
        if (data) {
          console.log('----------> booking details');
          let lastvalue = this.bookingDataLength;
          this.bookingDataLength = Object.keys(data).length;
          if (lastvalue < this.Length) {
            console.log('----------> Sound');
            this.playsound();
          }

          this.bookingData = Object.values(data);
          this.VechileDataID = [];
          this.VechileDetails = [];
          for (let i = 0; i < this.bookingDataLength; i++) {
            if (this.bookingData[i].vechicle_id) {
              //this.bookingDetailsSingle(this.bookingData[i].booking_id)

              this.VechileDataID.push({
                id: this.bookingData[i].booking_id,
                vechile_id: this.bookingData[i].vechicle_id,
                vechile_cat_id: this.bookingData[i].vechile_cat_id,
              });
              this.VechileDataDetails(
                this.bookingData[i].booking_id,
                this.bookingData[i].vechicle_id,
                this.bookingData[i].vechile_cat_id
              );
            }
          }
        }
      });
  }

  // If any addtional details is added first value is add for vechiledataDetails(functaion) and display the velaues in html
  VechileDataDetails(id: any, vechileId: any, cat_Id: any) {
    this.firebaseService
      .vechileDet(vechileId)
      .valueChanges()
      .subscribe((data) => {
        console.log('---------------->vechile details', data);

        this.vechileLat = data.latitude;
        this.vechileLng = data.longitude;

        this.VechileDetails.push({
          id: id,
          vechile_id: vechileId,
          vechile_cat_id: cat_Id,
          lat: this.vechileLat,
          lng: this.vechileLng,
        });

        console.log('-------------->data', this.VechileDetails);
      });
  }

  bookingDetailsSingle(id: any): void {
    this.firebaseService
      .bookingDet(id)
      .valueChanges()
      .subscribe((data) => {
        if (data) {
          this.VechileDataID.push({
            id: data.booking_id,
            vechile_id: data.vechicle_id,
          });
          console.log('----', this.VechileDataID);
          // this.vechileDetailsSingle(data.vechicle_id)
        }
      });
  }

  vechileDetailsSingle(id: any): void {
    this.firebaseService
      .vechileDet(id)
      .valueChanges()
      .subscribe((data) => {
        if (data) {
          let vechileDatas = Object.values(data);
          console.log('------->', vechileDatas);
          this.vechileLat = this.VechileDataID[0];
          this.vechileLng = this.VechileDataID[1];

          let ori = {
            lat: this.vechileLat,
            lng: this.vechileLng,
            label: 'Driver',
            icon: '../../../../assets/orig.png',
          };
          let des = {
            lat: this.destLat,
            lng: this.destLng,
            label: 'Destination',
            icon: '../../../../assets/dest.png',
          };
          let ori1 = [this.vechileLat, this.vechileLng];
          let des1 = [Number(this.destLat), Number(this.destLng)];
          this.lat = this.vechileLat;
          this.lng = this.vechileLng;
          this.origin = {
            lat: Number(this.vechileLat),
            lng: Number(this.vechileLng),
          };
          this.destination = {
            lat: Number(this.destLat),
            lng: Number(this.destLng),
          };
          this.markers = [];
          this.polyline = [];
          this.markers.push(ori);
          this.polyline.push(ori1);
          this.start_end_mark.push(this.polyline[0]);
          this.start_end_mark.push(this.polyline[this.polyline.length - 1]);
        }
      });
  }

  JSON_MAP_STYLES = [
    {
      featureType: 'administrative',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#444444',
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'all',
      stylers: [
        {
          color: '#f2f2f2',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 45,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          color: '#bae3f7',
        },
        {
          visibility: 'on',
        },
      ],
    },
  ];
}
